(function() {
  'use strict';

  /**
   * @ngdoc object
   * @name combo.controller:ComboCtrl
   *
   * @description
   *
   */
  angular
    .module('combo')
    .controller('ComboCtrl', ComboCtrl);

  function ComboCtrl($scope, $rootScope, $mdDialog, MyOrder) {
    $rootScope.pageTitle = "מבצעים";

    $scope.selectedCombo = undefined;
    $scope.selectedItems = [];
    $scope.selectedPizzas = [];

    $scope.selectCombo = function(combo) {
      $scope.selectedCombo = combo;
      $scope.selectedCombo.ItemCombos.forEach(function(comboItem) {

        var itemsToPush = $scope.getArray(comboItem.Quantity);
        itemsToPush.forEach(function(item) {
          item.comboItemId = comboItem.Id;
        })

        $scope.selectedItems.push({
          items:itemsToPush
        });

      })
      $scope.selectedCombo.PizzaCombos.forEach(function(comboPizza, index) {
        $scope.selectedPizzas.push({
          items: [$scope.getArray(comboPizza.Quantity)]
        })
        for (var i = 0; i < comboPizza.Quantity; i++) {
          $scope.selectPizzaSize(comboPizza.SizeId);
          comboPizza.Pizza.comboPizzaId = comboPizza.Id;
          $scope.selectedPizzas[index].items[i] = angular.copy(comboPizza.Pizza);
        }
      })
    }



    $scope.getArray = function(num) {
      return new Array(num);
    }

    $scope.addItem = function(item, comboItemIndex, itemIndex, comboItemId) {
      //if ($scope.selectedCombo.items == undefined) $scope.selectedCombo.items = $scope.getArray($scope.selectedCombo.length);
      item.comboItemId = comboItemId;
      $scope.selectedItems[comboItemIndex].items[itemIndex] = angular.copy(item);
    }

    $scope.itemGarnishesDialog = function(item) {
      //var item = $scope.selectedItems[comboItemIndex].items[itemIndex];
      $scope.currentItem = item;
      if ($scope.currentItem.toppings == undefined) $scope.currentItem.toppings = [];

      $mdDialog.show({
        templateUrl: 'dialogs/menuGarnishes.html',
        scope: $scope,
        preserveScope: true
      });
    }

    //Item Garnishes functions
    $scope.selectGarnish = function(garn) {
      garn.selected = !garn.selected;
    }

    $scope.addItemWithGarnishesToOrder = function() {
      $scope.currentItem.Garnishes.forEach(function(garn) {
        if (garn.selected) {
          $scope.currentItem.toppings.push(garn);
        }
      });
      $scope.hideDialog();
    }

    //Pizza toppings function
    $scope.addPizzaToOrder = function() {
      $scope.hideDialog();
    }

    $scope.addPizzaToppings = function(pizza, comboPizzaId) {
      $scope.currentPizza = pizza;
      $scope.currentPizza.comboPizzaId = comboPizzaId;
      if ($scope.currentPizza.toppings == undefined) $scope.currentPizza.toppings = [];
      $scope.setPizzaToppingForEdit();
      $mdDialog.show({
        templateUrl: 'dialogs/pizzaDetails.html',
        scope: $scope,
        preserveScope: true
      });
    }



    $scope.selectQuarter = function(quarterNum, topping) {
      switch (quarterNum) {
        case 0:
          if (!topping.firstQuarter) {
            topping.firstQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.firstQuarter, topping.Id, 1, topping.currentPrice / 4, topping.Name);
          } else if (!topping.secondQuarter) {
            topping.secondQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.secondQuarter, topping.Id, 2, topping.currentPrice / 4, topping.Name);
          } else if (!topping.thirdQuarter) {
            topping.thirdQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.thirdQuarter, topping.Id, 3, topping.currentPrice / 4, topping.Name);
          } else if (!topping.forthQuarter) {
            topping.forthQuarter = true;
            topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.forthQuarter, topping.Id, 4, topping.currentPrice / 4, topping.Name);
          }
          break;
        case 1:
          topping.firstQuarter = !topping.firstQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.firstQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name);
          break;
        case 2:
          topping.secondQuarter = !topping.secondQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.secondQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name)
          break;
        case 3:
          topping.thirdQuarter = !topping.thirdQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.thirdQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name)
          break;
        case 4:
          topping.forthQuarter = !topping.forthQuarter;
          topping.totalPrice = $scope.removeOrAddToppingToPizza(topping.forthQuarter, topping.Id, quarterNum, topping.currentPrice / 4, topping.Name)
          break;
        default:
      }
    }

    $scope.removeOrAddToppingToPizza = function(isAdd, toppingId, quarterNum, quarterPrice, toppingName) {
      if (isAdd) {
        var topping = _.find($scope.currentPizza.toppings, function(e) {
          return e.toppingId == toppingId
        })
        if (topping != undefined) {
          topping.quarterNums.push(quarterNum)
          topping.totalPrice = quarterPrice * topping.quarterNums.length;
          return quarterPrice * topping.quarterNums.length;
        } else {
          $scope.currentPizza.toppings.push({
            toppingId: toppingId,
            quarterNums: [quarterNum],
            totalPrice: quarterPrice,
            Name: toppingName,
            quarterPrice: quarterPrice
          })
          return quarterPrice;
        }

      } else {
        var topping = _.find($scope.currentPizza.toppings, function(e) {
          return e.toppingId == toppingId
        })
        var index = _.findIndex(topping.quarterNums, function(e) {
          return e == quarterNum
        });
        if (index >= 0) {
          topping.quarterNums.splice(index, 1);
        }
        topping.totalPrice = quarterPrice * topping.quarterNums.length;
        return topping.totalPrice;
      }
    }

    $scope.setPizzaToppingForEdit = function() {
      $scope.currentPizza.toppings.forEach(function(top) {
        top.quarterNums.forEach(function(quarterNum) {
          var originalTopping = _.find($scope.pizzaToppings, function(e) {
            return e.Id == top.toppingId
          });
          switch (quarterNum) {
            case 1:
              originalTopping.firstQuarter = true;
              break;
            case 2:
              originalTopping.secondQuarter = true;
              break;
            case 3:
              originalTopping.thirdQuarter = true;
              break;
            case 4:
              originalTopping.forthQuarter = true;
              break;
            default:

          }
        });
      })
    }

    $scope.selectPizzaSize = function(sizeId) {
      $scope.pizzaToppings.forEach(function(topping) {
        var toppingPrice = _.find(topping.ToppingPrices, function(e) {
          return e.PizzaSizeId == sizeId
        });
        topping.currentPrice = toppingPrice.Price;
        var quarterPrice = toppingPrice.Price / 4;

        // $scope.currentPizza.toppings.forEach(function(top) {
        // //  top.totalPrice = quarterPrice * top.quarterNums.length;
        //   //if (top.toppingId == topping.Id) topping.totalPrice = quarterPrice * top.quarterNums.length;
        //   top.quarterPrice = quarterPrice;
        // })

      })
    }


    $scope.hideDialog = function() {
      $scope.pizzaToppings.forEach(function(top) {
        top.firstQuarter = false;
        top.secondQuarter = false;
        top.thirdQuarter = false;
        top.forthQuarter = false;
        top.currentCalcPrice = undefined;
        top.totalPrice = undefined;
      })
      $mdDialog.hide();
    }

    $scope.addComboToOrder = function(valid) {
      if (!valid) return;
      MyOrder.addComboToOrder($scope.selectedCombo, $scope.selectedPizzas, $scope.selectedItems);
      $scope.selectedCombo = undefined;
    }
  }
}());
